<template>
  <div class="list-container">
    <div class="total d-f a-c">
      共找到<span class="num">{{ total }}</span
      >条结果满足条件
    </div>
    <div class="list">
      <div class="item-container d-f a-c" v-for="item in list" :key="item.id">
        <div class="item d-f a-c j-b">
          <div class="left d-f">
            <img
              src="@/assets/image/secondary_details_list_icon_word.png"
              v-if="item.fileTypeName === 'word'"
            />
            <img
              src="@/assets/image/secondary_details_list_icon_pdf.png"
              v-if="item.fileTypeName === 'pdf'"
            />
            <img
              src="@/assets/image/secondary_details_list_icon_ppt.png"
              v-if="item.fileTypeName === 'ppt'"
            />
            <img
              src="@/assets/image/secondary_details_list_icon_video.png"
              v-if="item.fileTypeName === 'video'"
            />
            <img
              src="@/assets/image/secondary_details_list_icon_zip.png"
              v-if="item.fileTypeName === 'package'"
            />
            <img
              src="@/assets/image/secondary_details_list_icon_other.png"
              v-if="item.fileTypeName === 'other'"
            />
            <div class="detail">
              <div class="title">{{ item.fileName }}</div>
              <div class="type">
                <a-tag color="#05AE44">
                  {{ item.isBoutique | handleBoutique }}
                </a-tag>
              </div>
              <div class="other d-f a-c">
                <img
                  src="@/assets/image/secondary_details_list_time.png"
                  class="time-img"
                />
                <span class="time">{{ item.updateTime }}</span>
                <img
                  src="@/assets/image/secondary_details_list_download.png"
                  class="download-img"
                />
                <span class="download">
                  <span class="download-num">{{ item.downloadCount }}</span
                  >次
                </span>
                <img
                  src="@/assets/image/secondary_details_list_size.png"
                  class="download-img"
                />
                <span class="download">{{ item.fileSize | handleSize }}</span>
                <img
                  src="@/assets/image/secondary_details_list_read.png"
                  class="download-img"
                />
                <span class="download">
                  <span class="download-num">{{ item.readCount }}</span
                  >次
                </span>
                <img
                  src="@/assets/image/secondary_details_list_collection.png"
                  class="download-img"
                />
                <span class="download">
                  <span class="download-num">{{ item.collectionCount }}</span
                  >次
                </span>
              </div>
            </div>
          </div>
          <a-button type="primary" class="btn" @click="toPreview(item)">
            查看详情
          </a-button>
        </div>
      </div>
      <div class="pagination-container">
        <a-pagination
          v-model="pageIndex"
          :pageSize="pageSize"
          :default-current="1"
          :total="total"
          :hideOnSinglePage="true"
          showLessItems
          @change="onChange"
          :item-render="itemRender"
          class="pagination-content"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageIndex: 1,
      pageSize: 6,
      total: null,
      list: [],
    };
  },
  filters: {
    handleBoutique(val) {
      if (val === true) {
        return "精品";
      } else {
        return "非精品";
      }
    },
    handleSize(val) {
      if (val != null) {
        let KB = val / 1024;
        let KBInt = Math.floor(KB);
        if (KBInt > 1024) {
          let MB = KB / 1024;
          let MBInt = Math.floor(MB);
          if (MBInt > 1024) {
            let GB = MB / 1024;
            return GB.toFixed(2) + "G";
          } else {
            return MB.toFixed(2) + "M";
          }
        } else {
          return KB.toFixed(2) + "KB";
        }
      }
    },
  },
  methods: {
    //分页状态改变
    onChange(pageNumber) {
      this.pageIndex = pageNumber;
      this.getDetailList();
    },
    //改变分页上一步下一步的文字链接
    itemRender(current, type, originalElement) {
      if (type === "prev") {
        return <a>上一页</a>;
      } else if (type === "next") {
        return <a class="next">下一页</a>;
      }
      return originalElement;
    },
    //二级详情列表
    getDetailList() {
      let navList = this.$ls.get("navList");
      let navOneId = this.$ls.get("navOneId");
      let subjectId = this.$ls.get("subjectId");

      if (subjectId === null) {
        subjectId = navList.filter((r) => r.id === navOneId)[0].children[0].id;
      }

      this.$api
        .getDetailList({
          identifier: this.$ls.get("identifier"),
          navOneId: this.$ls.get("navOneId"),
          subjectId: subjectId,
          gradeId: this.$ls.get("gradeId"),
          search: this.$ls.get("search"),
          pageNum: this.pageIndex,
          pageSize: this.pageSize,
        })
        .then((res) => {
          if (res.code === 200) {
            this.total = res.total;
            this.list = res.rows;
          } else {
            // console.log(res);
            this.$message.error(res.msg);
          }
        });
    },
    //去三级详情
    toPreview(item) {
      this.$router.push({
        path: "/preview",
        query: { navId: this.$ls.get("navOneId"), id: item.id },
      });
    },
    //重置列表数据
    resetDetailList() {
      this.pageIndex = 1;
      this.getDetailList();
    },
  },
  created() {
    this.getDetailList();
  },
};
</script>

<style scoped lang="less">
@main-color: #05ae44;
.list-container {
  margin-bottom: 88px;
  .total {
    width: 1200px;
    height: 60px;
    background: #ffffff;
    opacity: 1;
    margin-top: 36px;
    padding: 0 24px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    .num {
      color: rgba(237, 111, 33, 1);
    }
  }
  .list {
    width: 1200px;
    height: 1091px;
    background: #ffffff;
    opacity: 1;
    margin-top: 36px;
    padding: 0 37px 0 24px;
    box-sizing: border-box;
    .item-container {
      width: 100%;
      height: 169px;
      border-bottom: 1px dashed #bebebe;
      padding-right: 23px;
      box-sizing: border-box;
      .item {
        width: 100%;
        height: 96px;
        .left {
          height: 96px;
          img {
            height: 100%;
          }
          .detail {
            margin-left: 12px;
            .title {
              font-size: 16px;
              font-weight: bold;
              color: #333333;
              width: 600px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .type {
              font-size: 12px;
              font-weight: 400;
              color: #6a6a6a;
              margin-top: 12px;
            }
            .other {
              margin-top: 12px;
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              .time-img {
                width: 16px;
                height: 16px;
              }
              .time {
                margin-left: 4px;
              }
              .download-img {
                width: 16px;
                height: 16px;
                margin-left: 24px;
              }
              .download {
                margin-left: 4px;
                .download-num {
                  color: @main-color;
                }
              }
            }
          }
        }
        .btn {
          width: 140px;
          height: 48px;
          font-size: 14px;
        }
      }
    }
    .pagination-container {
      margin-top: 24px;
      width: 100%;

      .pagination-content {
        flex: 1;
        text-align: right;

        .ant-pagination-prev a {
          text-decoration: none;
          display: inline-block;
          width: 65px;
          height: 33px;
          border: 1px solid #d9dee5;
          border-radius: 2px;
        }

        .ant-pagination-next .next {
          text-decoration: none;
          display: inline-block;
          width: 65px;
          height: 33px;
          border-radius: 2px;
          border: none;
          background-color: @main-color;
          color: #fff;
        }
      }
    }
  }
}
</style>
