<template>
  <div class="secondary-details-container d-f f-d a-c">
    <page-nav></page-nav>
    <jump></jump>
    <search @clickBrotherBtn="clickBrotherBtn"></search>
    <screen @clickBrotherBtn="clickBrotherBtn"></screen>
    <list ref="borther"></list>
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageNav from "@/components/PageNav/index.vue";
import Jump from "@/components/Jump/index.vue";
import Search from "./search.vue";
import Screen from "./screen.vue";
import List from "./list.vue";
import PageFooter from "@/components/PageFooter/index.vue";
export default {
  components: {
    PageNav,
    Jump,
    Search,
    Screen,
    List,
    PageFooter,
  },
  methods: {
    clickBrotherBtn() {
      // 父组件通过$ref调用子组件1中的事件方法
      this.$refs.borther.resetDetailList();
    },
  },
  created() {},
};
</script>

<style scoped lang="less">
.secondary-details-container {
  width: 100%;
  min-height: 100vh;
  background: #f1f3f5;
}
</style>
