<template>
  <div class="page-footer-container">
    <div class="top d-f f-d a-c">
      <div class="jump d-f">
        <p @click="toAboutUs">关于我们</p>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <p @click="toProduct">产品中心</p>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <p @click="toDisclaimers">免责声明</p>
      </div>
      <div class="subscription d-f a-c">
        <div class="d-f f-d a-c">
          <img
            src="@/assets/image/page_footer_subscription.png"
            alt=""
            class="subscription-img"
          />
          <p class="subscription-text">微信公众号</p>
        </div>
        <div class="d-f f-d a-c">
          <img
            src="@/assets/image/page_footer_headset.png"
            alt=""
            class="subscription-headset"
          />
          <p class="subscription-time">公司电话(周一至周五 8:30~18:00)</p>
          <div class="subscription-phone">025-87183991</div>
        </div>
      </div>
    </div>
    <div class="bottom d-f f-d a-c">
      <div class="company">
        ©2021 XNIU 苏公网安备 32011502011289号 苏ICP备2020065401号
        江苏小牛电子科技有限公司
      </div>
      版权声明：以上文章所选用的图片及文字来源于网络，由于未联系到知识产权或未发现有关知识产权的登记，如有知识产权人并不愿意我们使用，如果有侵权请立即联系。
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toAboutUs() {
      window.open(`https://www.jsxniu.com/about-us.html`, "_blank");
    },
    toProduct() {
      window.open(`https://www.jsxniu.com/product.html`, "_blank");
    },
    toDisclaimers() {
      window.open(`https://www.jsxniu.com/disclaimers.html`, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
@main-color: #05ae44;
.page-footer-container {
  width: 1440px;
  background: #2f333c;
  padding: 24px 121px 29px;
  .top {
    width: 100%;
    border-bottom: 1px dashed rgba(112, 112, 112, 0.2);
    .jump {
      font-size: 14px;
      font-weight: 400;
      color: #d4d4d4;
      cursor: pointer;
      p {
        margin-bottom: 0;
      }
    }
    .subscription {
      //   height: 116px;
      margin-top: 24px;
      margin-bottom: 25px;
      .subscription-img {
        width: 96px;
      }
      .subscription-text {
        font-size: 12px;
        margin-top: 4px;
        font-weight: 400;
        line-height: 16px;
        color: #b3b1b1;
      }
      .subscription-headset {
        margin-left: 36px;
        width: 32px;
      }
      .subscription-time {
        margin-left: 36px;
        margin-top: 12px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #b3b1b1;
      }
      .subscription-phone {
        margin-left: 36px;
        font-size: 31px;
        font-weight: 400;
        line-height: 41px;
        color: @main-color;
      }
    }
  }
  .bottom {
    width: 100%;
    font-size: 11px;
    font-weight: 400;
    color: #b3b1b1;
    .company {
      margin-top: 13px;
      margin-bottom: 11px;
    }
  }
}
</style>
