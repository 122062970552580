<template>
  <div class="screen-container">
    <div class="subject d-f a-c">
      <div class="title">学科:</div>
      <a-radio-group v-model="value" @change="onChange">
        <a-radio-button :value="item.id" v-for="item in subjectList" :key="item.id">{{
          item.name
        }}</a-radio-button>
      </a-radio-group>
    </div>
    <div class="grade d-f a-c">
      <div class="title">学段:</div>
      <a-radio-group v-model="valueToo" @change="onChangeToo">
        <a-radio-button :value="null"> 全部 </a-radio-button>
        <a-radio-button :value="item.id" v-for="item in gradeList" :key="item.id">
          {{ item.name }}</a-radio-button
        >
      </a-radio-group>
      <a-button class="btn" v-if="canShow" @click="open">
        {{ text }} <a-icon :type="icon" />
      </a-button>
    </div>
    <div class="more d-f a-c" v-if="canOpen">
      <div class="title"></div>
      <a-radio-group v-model="valueToo" @change="onChangeThree">
        <a-radio-button :value="item.id" v-for="item in spaceList" :key="item.id">
          {{ item.name }}</a-radio-button
        >
      </a-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //学科的值
      value: "",
      //学段的值
      valueToo: null,
      //学科数组
      subjectList: [],
      //学段数组
      gradeList: [],
      spaceList: [],
      canShow: false,
      canOpen: false,
      text: "更多",
      icon: "down",
    };
  },
  computed: {},
  methods: {
    //学科改变
    onChange(e) {
      this.$ls.set("subjectId", e.target.value, 60 * 60 * 1000 * 24);

      let navList = this.$ls.get("navList");
      let liList = navList.filter((r) => r.identifier === this.$route.query.type);
      //学科数组
      let arr = liList[0].children;
      //学段总数组
      let arr1 = arr.filter((r) => r.id === this.$ls.get("subjectId"))[0].children;
      if (arr1.length > 9) {
        this.canShow = true;
        this.canOpen = true;
        this.text = "收起";
        this.icon = "up";
        let arr2 = arr1.splice(0, 9);
        this.gradeList = arr2;
        this.spaceList = arr1;
      } else {
        this.canShow = false;
        this.canOpen = false;
        this.gradeList = arr1;
      }

      this.$ls.set("gradeId", null, 60 * 60 * 1000 * 24);
      this.valueToo = null;

      this.$emit("clickBrotherBtn", true);
    },
    //学段改变
    onChangeToo(e) {
      this.$ls.set("gradeId", e.target.value, 60 * 60 * 1000 * 24);
      this.$emit("clickBrotherBtn", true);
    },
    //学科更多改变
    onChangeThree(e) {
      this.$ls.set("gradeId", e.target.value, 60 * 60 * 1000 * 24);
      this.$emit("clickBrotherBtn", true);
    },
    //更多和收起按钮
    open() {
      if (this.icon == "down") {
        this.text = "收起";
        this.icon = "up";
        this.canOpen = true;
      } else {
        this.text = "更多";
        this.icon = "down";
        this.canOpen = false;
      }
    },
  },
  created() {
    let navList = this.$ls.get("navList");
    let liList = navList.filter((r) => r.identifier === this.$route.query.type);
    //学科数组
    let arr = liList[0].children;
    this.subjectList = arr;
    if (this.$ls.get("subjectId") != null) {
      this.value = this.$ls.get("subjectId");
      //学段总数组
      let arr1 = arr.filter((r) => r.id === this.$ls.get("subjectId"))[0].children;
      if (arr1.length > 9) {
        this.canShow = true;
        this.canOpen = true;
        this.text = "收起";
        this.icon = "up";
        let arr2 = arr1.splice(0, 9);
        this.gradeList = arr2;
        this.spaceList = arr1;
      } else {
        this.gradeList = arr1;
      }
      if (this.$ls.get("gradeId") != null) {
        this.valueToo = this.$ls.get("gradeId");
      } else {
        this.valueToo = null;
      }
    } else {
      this.value = arr[0].id;
      //学段总数组
      let arr3 = arr[0].children;
      this.valueToo = null;
      if (arr3.length > 9) {
        this.canShow = true;
        this.canOpen = true;
        this.text = "收起";
        this.icon = "up";
        let arr4 = arr3.splice(0, 9);
        this.gradeList = arr4;
        this.spaceList = arr3;
      } else {
        return arr3;
      }
    }
  },
};
</script>

<style scoped lang="less">
@main-color: #05ae44;
.screen-container {
  width: 1200px;
  background: #fff;
  padding: 36px 24px;
  .subject {
    width: 100%;
    .title {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      margin-right: 5px;
      width: 50px;
    }
    .ant-radio-button-wrapper {
      color: #6a6a6a;
      background: #fff;
      margin-left: 8px;
      border: 1px solid transparent;
      border-radius: 2px;
    }
    .ant-radio-button-wrapper:hover {
      background: #f1f3f5;
    }
    .ant-radio-button-wrapper-checked {
      color: @main-color;
      border-radius: 2px;
      border: 1px solid #05ae44;
      box-shadow: none;
      background: #cfefdc;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      width: 0;
    }
  }
  .grade {
    width: 100%;
    margin-top: 16px;
    .title {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      margin-right: 5px;
      width: 50px;
    }
    .ant-radio-button-wrapper {
      color: #6a6a6a;
      background: #fff;
      margin-left: 8px;
      border: 1px solid transparent;
      border-radius: 2px;
    }
    .ant-radio-button-wrapper:hover {
      background: #f1f3f5;
    }
    .ant-radio-button-wrapper-checked {
      color: @main-color;
      border-radius: 2px;
      border: 1px solid #05ae44;
      box-shadow: none;
      background: #cfefdc;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      width: 0;
    }
    .btn {
      margin-left: 8px;
    }
  }
  .more {
    width: 100%;
    margin-top: 16px;
    .title {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      margin-right: 5px;
      width: 50px;
    }
    .ant-radio-button-wrapper {
      color: #6a6a6a;
      border-radius: 0px;
      background: #fff;
      margin-left: 8px;
      border: 1px solid transparent;
      border-radius: 2px;
    }
    .ant-radio-button-wrapper:hover {
      background: #f1f3f5;
    }
    .ant-radio-button-wrapper-checked {
      color: @main-color;
      border-radius: 2px;
      border: 1px solid #05ae44;
      box-shadow: none;
      background: #cfefdc;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      width: 0;
    }
  }
}
</style>
