<template>
  <div class="search-container d-f a-c j-c">
    <a-input-search
      placeholder="请输入搜索关键词"
      enter-button
      size="large"
      v-model="search"
      @search="onSearch"
      class="ipt"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
    };
  },
  methods: {
    onSearch(val) {
      this.$ls.set("search", val, 60 * 60 * 1000 * 24);
      this.$emit("clickBrotherBtn", true);
    },
  },
  created() {
    if (this.$ls.get("search") != null) {
      this.search = this.$ls.get("search");
    }
  },
};
</script>

<style scoped lang="less">
.search-container {
  width: 100%;
  height: 108px;
  .ipt {
    width: 491px;
  }
}
</style>
